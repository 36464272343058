<template>
    <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/coloradojackorders.webp" class="background-pic" />
    <div class="row">
        <div class="col-8 col-sm-8 mb-1 center">
            <div class="row">
                <h6 class="col-2 col-sm-2">Fundraiser Title and Amount</h6>
                <h6 class="col-2 col-sm-2">Fundraiser Dates</h6>
                <h6 class="col-2 col-sm-2">Description</h6>
                <h6 class="col-2 col-sm-2">Upload W-9 form</h6>
                <h6 class="col-2 col-sm-2">Create Fundraiser</h6>
            </div>
            <el-progress type="line" status="success" text-inside="true" stroke-width="30" :percentage="currentStep * 14">
                <template #default="{ percentage }">
                    <span class="percentage-value">{{ percentage }}%</span>
                </template>
            </el-progress>
        </div>
    </div>
    <div id="firstEvent" class="res-card center shadow-card mt-5 pt-5">
        <el-alert v-show="errorMessage.length>0" :title="errorMessage"
                  type="error"
                  effect="dark">
        </el-alert>
        <div class="widget step" v-if="currentStep==1">
            <el-form :model="event" :rules="step1Rules" ref="step1Form" :label-position="top" hide-required-asterisk="true">
                <div class="mb-2" style="text-align: center; color: #983222">
                    <h4 class="mb-3" style="font-size:large">Great, let's get started on creating your new Fundraiser!</h4>
                </div>
                <el-form-item label="YOU ARE FUNDRAISING FOR" prop="eventReasonId">
                    <el-select filterable v-model="event.eventReasonId" v-fancylabel>
                        <el-option v-for="item in eventReasonList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="TITLE OF FUNDRAISER" prop="title">
                    <el-input v-model="event.title" v-fancylabel v-on:keyup.enter="next('step1Form')"></el-input>
                </el-form-item>
                <el-form-item label="How much you would like to raise?" prop="targetAmount">
                    <el-input type="tel" v-model="event.targetAmount" v-fancylabel v-on:keyup.enter="next('step1Form')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="danger" style="background-color: #983222" @click="next('step1Form')" :disabled="!form1Valid">NEXT</el-button>
                </el-form-item>
                <div class="ml-3 mt-3">
                    <h6>Typically each seller averages $300-400, if you have 10 sellers, the total could be up to $4000</h6>
                </div>
            </el-form>
        </div>
        <div class="widget step" v-if="currentStep==2">
            <el-form :model="event" :rules="step2Rules" ref="step2Form" :label-position="top" hide-required-asterisk="true">
                <div class="form-title">
                    <h4>Set your event date</h4>
                </div>
                <el-form-item prop="startOption" v-if="hasRunningEvent==false">
                    <div class="row no-gutters">
                        <div class="col-4 text-left">When you want to start</div>
                        <div class="col-4"><el-radio v-model="event.startOption" label="now" border style="width:90%;padding:30px 50px 45px 30px">Now</el-radio></div>
                        <div class="col-4 justify-content-end d-flex"><el-radio v-model="event.startOption" label="later" border style="width:90%;padding:30px 50px 45px 30px">Later</el-radio></div>
                    </div>
                </el-form-item>
                <el-form-item label="PICK A DATE" v-if="hasRunningEvent||event.startOption=='later'" prop="startDate">
                    <div v-fancylabel>
                        <el-date-picker v-model="event.startDate" style="width:100%" :disabled-date="disabledEventStartDate"
                                        type="date"
                                        placeholder="Pick a Date"
                                        value-format="MM/DD/YYYY"
                                        format="MM/DD/YYYY">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="RUN FOR" prop="period">
                    <el-select v-model="event.period" v-fancylabel v-on:keyup.enter="next('step2Form')">
                        <el-option v-for="item in periodList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="currentStep--">BACK</el-button>
                    <el-button type="danger" style="background-color: #983222" @click="next('step2Form')">NEXT</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="widget step" v-if="currentStep==3">
            <el-form :model="event" :rules="step3Rules" ref="step3Form" :label-position="top" hide-required-asterisk="true">
                <div class="form-title">
                    <h4>Please add a description that describes your fundraising event</h4>
                </div>
                <el-form-item prop="description">
                    <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10}" v-model="event.description" v-on:keyup.enter="currentStep++"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="currentStep--" class="float-left">BACK</el-button>
                    <el-button type="danger" style="background-color: #983222" @click="currentStep++" :loading="loading" v-if="currentStep==3" class="float-left">NEXT</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="widget step" style="height:500px" v-if="currentStep==4">            
            <h5 class="text-center" style="font-weight:500">Please upload your W-9 information! If you need the W-9 form you can fill out a PDF online at <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">Visit irs w-9</a> then upload to this site.  If you have questions email us at <a href="mailto:info@coloradojackgiveback.com">info@coloradojackgiveback.com</a>.</h5>
            <div style="height:200px;margin: 0 auto; margin-bottom:20px">
                <el-upload class="avatar-uploader" drag:action="url+filename+query" :on-change="w9Selected" :auto-upload="false" drag>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <div class="el-upload__tip">Only files with an and .pdf .jpg .jpeg extension</div>
                </el-upload>
                <div>
                    <el-button type="primary" plain @click="currentStep--">BACK</el-button>
                    <el-button type="danger" style="background-color: #983222" v-if="this.w9Url!=''" @click="currentStep++" :loading="loading">NEXT</el-button>
                </div>
            </div>           
        </div>

        <div class="widget step" v-if="currentStep==5">
            <section>
                <div class="h4 font-weight-medium"> Fundraiser Details</div>
                <div class="row mt-4">
                    <div class="col-4 font-weight-medium">TITLE: </div>
                    <div class="col-8">{{event.title}}</div>
                </div>
                <div class="row mt-1">
                    <div class="col-4 font-weight-medium">ADD EVENT PHOTO:</div>
                </div>
                <div class="row">
                    <div class="col-12" style="height:200px; object-fit:contain; margin-top:0; margin-bottom:40px">
                        <ImageUploader @crop="setCropData" @image-selected="setSelectedImage" :showUploadButton="false" :image="event.imageSrc"></ImageUploader>
                    </div>
                </div>
                <div class="row mt-2">
                    <p class="text-left mr-5 ml-5" style="font-weight:500">Please add an image or picture that will represent your fundraiser. This image will be displayed on the Online Store that will be created. If no image is added, a default image will be displayed. Use the photo Cropper once the image is uploaded to make the photo Square if possible.</p>
                </div>

                <div class="row mt-4">
                    <div class="col-4 font-weight-medium">DESCRIPTION: </div>
                    <div class="col-8">{{event.description}}</div>
                </div>
                <div class="row mt-2">
                    <div class="col-4 font-weight-medium">FUNDRAISING FOR: </div>
                    <div class="col-8">{{selectedEventReason}}</div>
                </div>
                <div class="row mt-2">
                    <div class="col-4 font-weight-medium">TARGET: </div>
                    <div class="col-8 money">{{event.targetAmount}}</div>
                </div>
                <div class="row mt-2">
                    <div class="col-4 font-weight-medium">START DATE: </div>
                    <div class="col-8" v-if="event.startOption=='later'">{{event.startDate}}</div>
                    <div class="col-8" v-else>Right away</div>
                </div>
                <div class="row mt-2">
                    <div class="col-4 font-weight-medium">LASTS FOR: </div>
                    <div class="col-8">{{event.period}} {{event.period > 1? 'days':'day' }}</div>
                </div>
                <div class="row mt-2">
                    <div class="col-4 font-weight-medium">W-9 information: </div>
                    <div class="col-8">{{event.w9file.name}}</div>
                </div>
                <hr />
                <div class="">
                    <div>
                        <el-checkbox class="" @change="changecheckbox" v-model="consent" label="I confirm that I'm at least 18 years old." size="large"></el-checkbox>
                    </div>
                    <div class="row col-10">
                        <h6 class="ml-5">Note: Fundraiser participants can be under the age of 18.</h6>
                    </div>
                    <div>
                        <el-checkbox class="" @change="termscheckbox" v-model="terms" label="I agree to the terms and conditions of this site." size="large"></el-checkbox>
                        <!--<router-link style="padding-top:0px; padding-bottom:10px;" :to="{ name: 'TermsOfUse' }" class="nav-link">Terms and Conditions</router-link>-->
                        <el-button type="text" @click="termsDialogVisible = true">Terms and Conditions</el-button>
                        <el-dialog v-model="termsDialogVisible" title="Terms and Conditions" :width="dialogWidth" center>
                            <div class="iframe-container">
                                <iframe src="https://coloradojackgivebacksa.blob.core.windows.net/termsandconditions/ColoradoJackGiveBack.TERMS.pdf" frameborder="0" width="100%" height="1000"></iframe>
                            </div>
                        </el-dialog>
                    </div>
                    <el-button type="primary" plain @click="currentStep--">BACK</el-button>
                    <el-button type="danger" style="background-color: #983222" @click="submitEvent" :loading="loading" v-if="this.consent&&this.terms">SUBMIT</el-button>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import store from "@/store"
    import router from "../router";
    import { mapGetters } from "vuex"
    import ImageUploader from "./ImageUploader"

    export default {
        name: 'NewEvent',
        components: { ImageUploader },
        data() {
            return {
                isMounted: false,
                currentStep: 1,
                eventReasonList: [],
                errorMessage: '',
                step3FormValid: false,
                periodList: [{ name: '', value: null }, { name: '5 Days', value: 5 }],
                loading: false,
                loading1: false,
                file: null,
                fileUrl: "",
                w9Url: "",
                runningEvent: {},
                consent: false,
                terms: false,
                termsDialogVisible: false,
                event: {
                    title: '',
                    eventReasonId: null,
                    targetAmount: null,
                    startDate: '',
                    startOption: 'now',
                    period: null,//event period in days
                    description: '',
                    imageCropData: {},
                    w9file: {},
                    createdFromWeb: true,
                },
                step1Rules: {
                    title: [
                        { required: true, message: 'Fundraising Title is required', trigger: 'change' }
                    ],
                    eventReasonId: [
                        { required: true, message: 'Fundraising reason is required', trigger: 'change' }
                    ],
                    targetAmount: [/* eslint-disable-next-line no-useless-escape*/
                        { required: true, type: 'string', pattern: /^\d+(\.\d{1,2})?$/g, message: 'Enter the Fundraiser total target amount in dollars: For Example $2000', trigger: 'blur' }
                    ]
                },
                step2Rules: {
                    fundReason: [
                        { required: true, message: 'Please tell us the reason of the fund', trigger: 'change' }
                    ],
                    startOption: [
                        { required: true, trigger: 'change' }
                    ],
                    startDate: [
                        { type: 'date', required: true, message: 'Pick a start date for the event', trigger: 'change' }
                    ],
                    period: [
                        { required: true, message: 'Select the event period', trigger: 'change' }
                    ],
                },
                step3Rules: {
                    description: [

                    ]
                },               
            }
        },
        methods: {
            changecheckbox(val) {
                this.consent = val;
            },
            termscheckbox(val) {
                this.terms = val;
            },
            next(stepForm) {
                this.$refs[stepForm].validate((valid) => {
                    if (valid)
                        this.currentStep++;
                });
            },
            prev() {
                this.currentStep--;
            },
            validateForm(form) {
                let isValid = false;
                this.$refs[form].validate((valid) => {
                    isValid = valid;
                });
                return isValid;
            },
            setSelectedImage(file) {
                this.file = file;
                this.fileUrl = URL.createObjectURL(file.raw);                
            },
            setCropData(data) {
                this.event.imageCropData = data;                
            },
            async fetchLookups() {
                await axios.get('/api/Lookup/EventReasons').then(response => {
                    if (response.status === 200) {
                        this.eventReasonList = response.data;
                    }
                });
            },
            async submitEvent() {
                this.loading = true;
                // fix for TypeError: toLocaleDateString is not a function error
                if (this.event.startOption === 'now') {
                    this.event.startDate = new Date();
                }
                else {
                    const dt = new Date(this.event.startDate);
                    var newDateOptions = {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric"                        
                    }
                    this.event.startDate = dt.toLocaleDateString("en-US", newDateOptions);
                }

                var formData = new FormData();
                if (this.file != null) {
                    formData.append("file", this.file.raw);
                    for (const [key, value] of Object.entries(this.event.imageCropData)) {
                        formData.append("imageCropData." + key, value);
                    }
                }

                for (const [key, value] of Object.entries(this.event)) {
                    if (key === "imageCropData")
                        continue;
                    formData.append(key, value);
                }

                //add w9file to formdata
                if (this.event.w9file != null) {
                    formData.append("w9file", this.event.w9file.raw);
                    //for (const [key, value] of Object.entries(this.event.w9file)) {
                    //    formData.append("w9filedata." + key, value);
                    //}                                   
                }  

                await axios.post('/api/events', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.loading = false;
                    this.event.id = response.data.id;
                    this.event.link = response.data.link;
                    //store.dispatch("routeUser");
                    router.push('/o/dashboard');
                }).catch((error) => {
                    this.errorMessage = error.response.data;
                    this.loading = false;
                });
            },
            disabledEventStartDate(date) {
                return new Date(this.runningEventEndDate) >= date;
            },
            w9Selected(file) {
                this.w9Url = URL.createObjectURL(file.raw);
                this.event.w9file = file;
                console.log(this.w9Url);
            },
            handleUploadError() {
                console.log("File upload failed.");
            },
            handleUploadSuccess() {
                console.log("File upload succeeded!");
            },
        },
        computed: {
            form1Valid() {
                let isValid = true;
                //if (!this.isMounted)
                //    return false;
                //this.$refs['step1Form'].validate((valid) => {
                //    isValid = valid;
                //    });
                return isValid;
            },
            selectedEventReason() {
                return this.eventReasonList.find((item) => item.id === this.event.eventReasonId).name;
            },
            ...mapGetters([
                'hasRunningEvent', 'runningEventEndDate'
            ]),
            dialogWidth() {
                if (screen.width <= 576)
                    return "100%";
                else return "500px";
            }
        },
        mounted() {
            this.isMounted = true;
            if (this.hasRunningEvent === true)
                this.event.startOption = "later";
        },
        created() {
            this.fetchLookups();

        }
    }
</script>


<style scoped>
    .email-code input {
        text-align: center;
    }

    button.facebook-button {
        background-color: #3b579d;
        border: 0;
        border-radius: 3px;
        padding: 10px 20px;
        color: white;
        font-size: 1rem;
        min-width: 250px;
    }

    button.google-button {
        background-color: #DB4437;
        border: 0;
        border-radius: 3px;
        padding: 10px 20px;
        color: white;
        font-size: 1rem;
        min-width: 250px;
    }

    button.facebook-button i, button.google-button i {
        font-size: 1.3rem;
    }

    .background-pic {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        position: fixed;
        left: 0;
        right: 0;
        opacity: .2;
        bottom: 0;
        z-index: -1;
    }

    .res-card {
        background-color:white;
    }
    /*.shadow-card {
        box-shadow: rgba(0, 0, 0, 0.75) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    }*/
    .iframe-container {
        overflow: hidden;
        /* 16:9 aspect ratio */
        padding-top: 100%;
        position: relative;
    }

    .iframe-container iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 350px) {
        h4 {
            font-size: .90rem;
        }

        h5 {
            font-size: .80rem;
        }

        h6 {
            font-size: .55rem;
        }
        /*1rem = 16px*/
    }

    @media (min-width: 750px) {
        h4 {
            font-size: 1.0rem;
        }

        h5 {
            font-size: .90rem;
        }

        h6 {
            font-size: .80rem;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 1850px) {
        h4 {
            font-size: 1.2rem;
        }

        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: .90rem;
        }
        /*1rem = 16px*/
    }

</style>
