<template>
    <div class="payment-form-button">
        <div class="mb-3">
            <div class="el-icon-warning" style="color:red; font-size: medium" v-if="paymentError != ''">
                {{paymentError}}
            </div>
        </div>
        <hr class="d-none d-sm-block" />
        <div class="row" v-if="this.googleApplePaySupported">
            <div class="col-12 font-weight-bold" v-if="this.googleApplePaySupported">Or Pay now and bypass checkout</div>
        </div>
        <div id="payment-request-button">           
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import store from "@/store"

    export default {
        name: 'PaymentFormButton',
        components: {},
        props: {
            customerName: String,
            total: String,
            product: {},
            filledBags: []
        },
        data() {
            return {
                errorMessage: '',
                orderCode: '',
                method: 'applegoogleButton',
                googleApplePaySupported: true,
                stripePK: '',
                stripeConnectedAccount: '',
                stripe: null,
                stripeElements: null,
                card: null,
                paymentError: '',
                clientSecret: '',
                shippingMethods: [],
                checkout: {
                    total: 0,
                    status: '',
                    orderNum: '',
                    email: '',
                    newsUpdate: false,
                    deliveryMethod: 'ship',
                    selectedPickupLocation: '',
                    selectedShippingMethod: '',
                    billingAddressAsShippingAddress: 'yes',
                    shippingFee: 0,
                    shippingAddressString: '',
                    billingAddressString: '',
                    shippingMethod: '',
                    shippingAddress: {
                        fullName: '',
                        country: 'USA',
                        firstName: '',
                        lastName: '',
                        company: '',
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        phone: ''
                    },                   
                    billingAddress: {
                        fullName: '',
                        country: 'USA',
                        firstName: '',
                        lastName: '',
                        company: '',
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        phone: ''
                    },
                    payment: {
                        method: 'card',
                        token: null,
                        cardNumber: null,
                        cardExpiry: null,
                        cardCvc: null,
                    }
                },
            }
        },
        methods: {
            completePayment() {
                let $this = this;
                if (this.method == 'applegoogleButton') {
                    $this.stripe.confirmCardPayment(this.clientSecret, {
                        payment_method: {
                            card: $this.card,
                            billing_details: {
                                name: $this.customerName
                            }
                        }
                    }).then(function (result) {
                        if (result.error) {
                            // Show error to your customer (e.g., insufficient funds)
                            console.log(result.error.message);
                            $this.paymentError = result.error.message;
                            $this.$emit('payment-success', false, $this.method);
                        } else {
                            // The payment has been processed!
                            if (result.paymentIntent.status === 'succeeded') {
                                // Show a success message to your customer
                                // There's a risk of the customer closing the window before callback
                                // execution. Set up a webhook or plugin to listen for the
                                // payment_intent.succeeded event that handles any business critical
                                // post-payment actions.
                                $this.$emit('payment-success',true, $this.method);
                            }
                        }
                    });
                }
            },          
            saveOrder(cust) {
                //save order
                //proceed with the checkout
                let $this = this;

                //TODO - need work here to get shipto and billto correct.  Need shipping on button also...
                let NameArray = cust.payerName.split(" ");

                this.checkout.shippingAddress.fullName = cust.payerName;
                this.checkout.shippingAddress.firstName = typeof NameArray[0] === "undefined" ? cust.payerName : NameArray[0];
                this.checkout.shippingAddress.lastName = typeof NameArray[2] === "undefined" ? cust.payerName : NameArray[2];
                this.checkout.shippingAddress.addressLine1 = typeof cust.paymentMethod.billing_details.address.line1 === "undefined" ? '' : cust.paymentMethod.billing_details.address.line1;
                this.checkout.shippingAddress.city = cust.paymentMethod.billing_details.address.city;
                this.checkout.shippingAddress.state = cust.paymentMethod.billing_details.address.state;
                this.checkout.shippingAddress.zipCode = cust.paymentMethod.billing_details.address.postal_code;
                this.checkout.email = cust.payerEmail;
                this.checkout.paymentMethod = cust.paymentMethod;
                this.checkout.deliveryMethod = 'ship';

                this.checkout.billingAddress.fullName = cust.payerName;
                this.checkout.billingAddress.firstName = typeof NameArray[0] === "undefined" ? cust.payerName : NameArray[0];
                this.checkout.billingAddress.lastName = typeof NameArray[2] === "undefined" ? cust.payerName : NameArray[2];
                this.checkout.billingAddress.addressLine1 = typeof cust.paymentMethod.billing_details.address.line1 === "undefined" ? '' : cust.paymentMethod.billing_details.address.line1;
                this.checkout.billingAddress.city = cust.paymentMethod.billing_details.address.city;
                this.checkout.billingAddress.state = cust.paymentMethod.billing_details.address.state;
                this.checkout.billingAddress.zipCode = cust.paymentMethod.billing_details.address.postal_code;

                this.checkout.shippingAddressString = this.checkout.shippingAddress.fullName + ', ' + this.checkout.shippingAddress.addressLine1 + ', ' + this.checkout.shippingAddress.city + ', ' + this.checkout.shippingAddress.state + ', ' + this.checkout.shippingAddress.zipCode;
                this.checkout.billingAddressString = this.checkout.billingAddress.fullName + ', ' + this.checkout.billingAddress.addressLine1 + ', ' + this.checkout.billingAddress.city + ', ' + this.checkout.billingAddress.state + ', ' + this.checkout.billingAddress.zipCode;
                                
                this.checkout.total = !this.product.freeShipping ? (Number((this.total)) + ((this.product.shippingCost) * this.product.count)) : (Number(this.total));

                let order = {
                    total: this.checkout.total,
                    shippingFee: this.checkout.shippingFee,
                    contactEmail: this.checkout.email,
                    newsUpdate: false,
                    deliveryMethod: 'ship',
                    pickupLocationId: '1',
                    shippingMethodId: this.product.shippingMethodID,
                    baddressAsSAddress: this.checkout.billingAddressAsShippingAddress == 'yes',
                    shippingAddress: this.checkout.shippingAddress,
                    billingAddress: this.checkout.billingAddressAsShippingAddress == 'yes' ? this.checkout.shippingAddress : this.checkout.billingAddress,
                    existingCode: this.orderCode
                }

                //post order
                order.products = [];
                let prod = { productId: this.product.id, count: this.product.count, storeCode: store.getters.storeCode, price: this.product.price, bags: [] };
                //let product = { productId: p.id, count: p.count, storeCode: p.storeCode, bags: [] };
                //let prod = {
                //    id: this.product.id, name: this.product.name, freeShipping: this.product.freeShipping, total: this.totalPrice, quantity: this.product.quantity, count: this.product.count, storeCode: store.getters.storeCode, price: this.product.price, imageSrc: this.product.imageSrc, bags: []
                //};

                if (this.product.bagsList.length == 1) {
                    prod.total = prod.price * prod.count;
                    let item = this.product.bagsList[0];
                    prod.bags.push({ bagId: this.product.bags, name: this.product.name, imageSrc: this.product.imageSrc, price: prod.price, total: prod.total, count: this.product.quantity });
                }

                this.filledBags.forEach(b => {
                    prod.bags.push({ bagId: b.id, count: b.count })
                })
                
                order.products.push(prod);
                //let jstring = JSON.stringify(order);
                //alert(jstring);
                //$this.paymentError = jstring;
                                
                return new Promise((resolve, reject) => {
                    if (cust.paymentMethod.billing_details.address.line1 === null) {
                        resolve({ 'data': { 'status': 'Fail', 'message': 'no shipto address found on card' } });
                    }
                    axios.post('/api/orders', order).then(response => {
                        if (response.data.status === 'success') {
                            this.orderCode = response.data.code;
                            this.checkout.orderNum = response.data.number;
                            this.checkout.status = 'success';
                            this.clientSecret = response.data.client_secret;
                            resolve(response);
                        }
                    }).catch((error) => {
                        $this.paymentError = error.response.data;
                        alert(error.response.data);
                        this.errorMessage = error.response.data;
                        reject(error);
                    });
                });                
            },
            //async GetShippingMethods() {
            //    //Get shippingmethod for product           
            //    await axios.get('/api/lookup/shippingmethods').then(response => {
            //        this.shippingMethods = response.data;
            //        this.checkout.selectedShippingMethod = this.shippingMethods.filter(item => item.id == this.product.shippingMethodID);
            //    }).catch((error) => {
            //    });
            //}
        },
        computed: {
           
        },
        mounted() {
            //Get shippingmethod for product           
            //this.GetShippingMethods();
            let $this = this;          

            axios.get("/api/Common/StripePK").then(response => {
                $this.StripePK = response.data;
                $this.stripe = window.Stripe(`${$this.StripePK[0]}`, { apiVersion: "2020-08-27", stripeAccount: `${$this.StripePK[1]}` });
                $this.stripeElements = $this.stripe.elements();              
           
                 //create payment button request
                var paymentRequest = $this.stripe.paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: {
                        label: 'Colorado Jack GiveBack Fundraising Order',
                        amount: Math.floor($this.total * 100),
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,

                    requestShipping: true,
                    // `shippingOptions` is optional at this point:                   

                    shippingOptions: [
                        // The first shipping option in this list appears as the default
                        // option in the browser payment interface.
                        {
                            id: $this.product.freeShipping ? 'free-shipping' : 'shipping-rate',
                            label: $this.product.freeShipping ? 'Free shipping' : 'Shipping rate',
                            detail: 'Usually Ships in 1 to 3 days, you will recieve an email notification once the product ships.',
                            amount: $this.product.freeShipping ? 0 : Math.floor($this.product.shippingCost * 100),
                        },
                    ],
                });

                var prButton = $this.stripeElements.create('paymentRequestButton', {
                    paymentRequest: paymentRequest,                   
                });

                prButton.on('click', function (ev) {
                    paymentRequest.update({
                        total: {
                            amount: !$this.product.freeShipping ? (($this.total * 100) + (($this.product.shippingCost * 100) * $this.product.count)) : ($this.total * 100),
                            label: 'Colorado Jack GiveBack Fundraising Order'
                        },
                    })
                })

                // Check the availability of the Payment Request API first.
                paymentRequest.canMakePayment().then(function (result) {
                    if (result) {
                        prButton.mount('#payment-request-button');                       
                    } else {
                        $this.googleApplePaySupported = false;
                    }
                });

                paymentRequest.on('shippingaddresschange', function (ev) {
                    if (ev.shippingAddress.country !== 'US') {
                        ev.updateWith({ status: 'invalid_shipping_address' });
                    } else {
                        ev.updateWith({
                            status: 'success',
                        });
                    }
                });
               
                paymentRequest.on('paymentmethod', function (ev) {
                    $this.saveOrder(ev).then(function (result) {
                        if (result.data.status !== 'success') {
                            ev.complete('fail');
                            ev.message = result.data.message;
                            $this.paymentError = result.data.message;
                            //$this.$emit('payment-fail', $this.method, result);
                        } else {
                            // Confirm the PaymentIntent without handling potential next actions (yet).                   
                            $this.stripe.confirmCardPayment(
                                $this.clientSecret,
                                { payment_method: ev.paymentMethod.id },
                                { handleActions: false }
                            ).then(function (confirmResult) {
                                if (confirmResult.error) {
                                    // Report to the browser that the payment failed, prompting it to
                                    // re-show the payment interface, or show an error message and close
                                    // the payment interface.
                                    ev.complete('fail');
                                    $this.$emit('payment-fail', $this.method);
                                } else {
                                    // Report to the browser that the confirmation was successful, prompting
                                    // it to close the browser payment method collection interface.
                                    ev.complete('success');
                                    $this.$emit('payment-success', $this.method, $this.checkout);
                                    // Check if the PaymentIntent requires any actions and if so let Stripe.js
                                    // handle the flow. If using an API version older than "2019-02-11"
                                    // instead check for: `paymentIntent.status === "requires_source_action"`.
                                    if (confirmResult.paymentIntent.status === "requires_action") {
                                        // Let Stripe.js handle the rest of the payment flow.
                                        $this.stripe.confirmCardPayment($this.clientSecret).then(function (result) {
                                            if (result.error) {
                                                // The payment failed -- ask your customer for a new payment method.
                                                $this.$emit('payment-fail', $this.method);
                                            } else {
                                                // The payment has succeeded.
                                                $this.$emit('payment-success', $this.method);
                                            }
                                        });
                                    } else {
                                        // The payment has succeeded.
                                        $this.$emit('payment-success', $this.method);
                                    }
                                }
                            });

                        }
                    });
                        
                });

            });

        },
        created() {
        }
    }
</script>


<style scoped>
  
</style>
