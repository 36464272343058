<template>
    <div class="container">
        <div style="max-width:600px;margin: 0 auto;">
            <section class="h3 font-weight-bold text-center mt-5">
                <i class="el-icon-success d-block " style="font-size:3.5rem;margin-bottom:25px;color:#67C23A"></i>
                <span>YOUR ORDER HAS BEEN COMPLETED</span>
                <h6 class="font-weight-light mb-2">You will be recieving an email confirmation for your order at the email address of {{order.email}} shortly!</h6>
            </section>
            <hr />
            <div class="order-detail">
                <div class="row mb-2">
                    <div class="col-4 font-weight-bold">ORDER NUMBER:</div>
                    <div class="col-8">{{order.number}}</div>
                </div>
                <div class="row mb-2">
                    <div class="col-4 font-weight-bold">ORDER TOTAL:</div>
                    <div class="col-8">${{order.total.toFixed(2)}}</div>
                </div>
                <div class="row mb-2" v-if="order.email">
                    <div class="col-4 font-weight-bold">CONTACT EMAIL:</div>
                    <div class="col-8">{{order.email}}</div>
                </div>
                <div class="row mb-2">
                    <div class="col-4 font-weight-bold">DELIVERY METHOD:</div>
                    <div class="col-8" v-if="order.deliveryMethod=='ship'"><span class="font-weight-bold">Ship to:</span>{{' ' + order.shippingAddressString}}</div>
                    <div class="col-8" v-else><span class="font-weight-bold">Pickup from:</span>{{' ' + order.pickupLocationAddress}}</div>
                </div>
                <!--<div class="row mb-2" v-if="order.deliveryMethod=='ship'">
        <div class="col-4 font-weight-bold">SHIPPING ADDRESS</div>
        <div class="col-8">{{order.shippingAddressString}}</div>
    </div>-->
                <div class="row mb-2">
                    <div class="col-4 font-weight-bold">BILLING ADDRESS:</div>
                    <div class="col-8">{{order.billingAddressString}}</div>
                </div>
                <div class="row mt-2">
                    <h3 class="col-12 text-center">
                        <router-link :to="storeLink" class="nav-link" style="color:#4d4d4d;text-decoration: underline;">Continue Shopping</router-link>
                    </h3>
                </div>
            </div>
        </div>
    </div>       
</template>

<script>

    import store from "@/store"

    export default {
        name: 'OrderComplete',
        components: {  },
        data() {
            return {
                
            }
        },
        computed: {
            order() {
                return store.getters.completeOrder;
            },
            storeLink() {
                return '/store/public/' + store.getters.storeCode;
            }
        },
        methods: {
            
        },      
        created() {
           
        }
    }
</script>


<style>
  
</style>
