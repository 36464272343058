<template>
    <header class="mt-4">
       <!-- <nav class="navbar mt-2" style="background-color: transparent"></nav>-->
        <nav class="navbar navbar-fixed-top navbar-expand-lg navbar-toggleable-lg navbar-light border-bottom box-shadow pl-md-5 pr-md-5" style="background-color: #983222">
            <button id="navbar-toggler" class="navbar-toggler"
                    type="button"
                    data-toggle="collapse1"
                    data-target=".navbar-collapse"
                    aria-label="Toggle navigation"
                    @click="collapse1"
                    style="background-color: white">
                <span class="navbar-toggler-icon" ></span>
            </button>

            <div>
                <a>
                    <!--<img src="~@/assets/images/CJGivebackLogoOptionsv25.png" alt="Logo" style=" height:40px; width: 40px; margin-top: -10px;" />-->
                    <!--<img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/cjlogo.svg" alt="Logo" style="height: 140px; width: 150px; margin-top: -40px; margin-bottom: -50px" />-->
                    <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/cjlogo.svg" class="logo" alt="Logo" style="" />
                    <!--<span class="navbar-brand" style="color: white">COLORADO JACK GIVEBACK</span>-->
                </a>
            </div>

            <div v-if="cart!=undefined && cart.total > 0" class="d-block d-sm-none">
                <router-link class="h3" :to="{ name: 'Cart' }">
                    <i class="el-icon-shopping-bag-2" style=" color: rgba(255, 255, 255, 255)"></i>
                    <el-badge :value="cart.products.length>99?'99+':cart.products.length" v-if="parseInt(cart.products.length) > 0" class="count">
                    </el-badge>
                </router-link>
            </div>
            <div id="navbar-collapse" class="navbar-collapse collapse d-lg-inline-flex flex-lg-row-reverse justify-content-center" v-bind:class="{show: isExpanded}">
                <ul class="navbar-nav flex-grow">
                    <li class="nav-item font-weight-bold ">
                        <a :href="publicWebsite" class="nav-link" style="color: white;" @click="collapse">HOME</a>
                        <!--<router-link :to="{ name: 'Home' }" class="nav-link" style="color: white;" @click="collapse">HOME</router-link>-->
                    </li>
                    <li class="nav-item" v-if="isAuthenticated">
                        <a class="nav-link c-pointer" @click="toDashboard" style="color: white;">DASHBOARD</a>
                    </li>
                    <li class="nav-item font-weight-bold" v-if="isAuthenticated&&!hasRunningEvent">
                        <router-link :to="{ name: 'NewEvent' }" class="nav-link" style="color: white;" @click="collapse">NEW FUNDRAISER</router-link>
                    </li>
                    <li class="nav-item" v-if="!isAuthenticated">
                        <router-link :to="{ name: 'Login', params: { signup: 1 }}" class="nav-link" style="color: white;" title="Create a new fundraiser!" @click="collapse">CREATE ONLINE FUNDRAISER</router-link>
                    </li>
                    <li class="nav-item" v-if="!isAuthenticated">
                        <router-link :to="{ name: 'Login', params: { signup: 2 }}" class="nav-link" style="color: white;" title="Join a fundraiser!" @click="collapse">JOIN ONLINE FUNDRAISER</router-link>
                    </li>
                                <!--<li class="nav-item" v-if="!isAuthenticated">
                    <a :href="classicfundraiser" class="nav-link" style="color: white;" @click="collapse">CLASSIC FUNDRAISER</a>-->
                                <!--<router-link :to="{ name: 'BrochureForm' }" class="nav-link" style="color: white;" title="Create a Classic new fundraiser!" @click="collapse">START YOUR CLASSIC FUNDRAISER!</router-link>-->
                                <!--</li>-->
                                <!--<li class="nav-item font-weight-bold ">
                    <a :href="help" class="nav-link" style="color: white;" @click="collapse">HELP</a>-->
                                <!--<router-link :to="{ name: 'Help' }" class="nav-link" style="color: white;" @click="collapse">HELP</router-link>-->
                                <!--</li>
                <li class="nav-item font-weight-bold ">
                    <a :href="blog" class="nav-link" style="color: white;" @click="collapse">BLOG</a>
                </li>
                <li class="nav-item font-weight-bold ">
                    <a :href="about" class="nav-link" style="color: white;" @click="collapse">ABOUT US</a>-->
                    <!--<router-link :to="{ name: 'About' }" class="nav-link" style="color: white;" @click="collapse">ABOUT US</router-link>-->
                    <!--</li>-->
                    <li class="nav-item mt-1" v-if="!isAuthenticated">
                        <!-- <i class="el-icon-user" style=" color: rgba(255, 255, 255, 255)"></i>-->
                        <router-link :to="{ name: 'Login', params: { signup: 0 }}" class="nav-link el-icon-user" title="Sign In" style="color: white;" @click="collapse"></router-link>
                    </li>
                    <li class="nav-item dropdown" v-if="isAuthenticated">
                        <div class="row no-gutters">
                            <div class="col">
                                <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white;">
                                    {{currentUserDetail.firstName + ' ' + currentUserDetail.lastName}}
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <router-link :to="{ name: 'Settings' }" class="dropdown-item c-pointer" style="color: #983222;" @click="collapse">SETTINGS</router-link>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item c-pointer" @click="logout" style="color: #983222;">SIGNOUT</a>
                                </div>
                            </div>
                            <div class="col profile-icon">
                                <el-image :src="currentUserDetail.imageSrc">
                                    <template #error>
                                        <div class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </template>
                                </el-image>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="cart!=undefined && cart.total > 0" class="d-none d-sm-block">
                <router-link class="h3" :to="{ name: 'Cart' }" @click="collapse">
                    <i class="el-icon-shopping-bag-2" style=" color: rgba(255, 255, 255, 255)"></i>
                    <el-badge :value="cart.products.length>99?'99+':cart.products.length" v-if="parseInt(cart.products.length) > 0" class="count">
                    </el-badge>
                </router-link>
            </div>
        </nav>
    </header>
</template>


<script>
    import { mapGetters } from "vuex"
    import store from "@/store"
    import router from "../router";
    import axios from "axios"

    export default {
        name: "NavMenu",
        data() {
            return {
                isExpanded: false,
                publicWebsite: "",
                coloradoJacksite: "",
                classicfundraiser: "",
                help: "",
                blog: "",
                about: ""
            }
        },
        methods: {
            collapse() {
                this.isExpanded = false;
            },
            collapse1() {
                if (this.isExpanded) {
                    this.isExpanded = false;
                }
                else {
                    this.isExpanded = true;
                }
            },
            toggle() {
                this.isExpanded = true;
            },
            logout() {
                store.dispatch("logout");
                this.isExpanded = false;

                //route to the publicwebsite
                window.location.href =  this.publicWebsite;
            },
            toDashboard() {
                store.dispatch("routeUser");
                this.isExpanded = false;
            },
            toNewEvent() {
                router.push("/NewEvent");
                this.isExpanded = false;
            },
            //closeNavBarFromChild() {
            //    this.$emit('closeNav') // Emit the event that the parent is listening
            //}
        },
        computed: {
            ...mapGetters([
                'isAuthenticated', 'cart', 'currentUserDetail', 'hasRunningEvent', 'runningEventEndDate'
            ]),
            iconSize() {
                if (screen.width <= 576)
                    return "80%";
                else return "100%";
            },
        },
        created() {
            axios.get('/api/common/publicwebsite').then(response => {
                this.publicWebsite = response.data;
                this.classicfundraiser = response.data + "/brochureform";
                this.help = response.data + "/help";
                this.about = response.data + "/about";
                this.blog = response.data + "/blog";
            }).catch((error) => {
            });
            //axios.get('/api/common/coloradojacksite').then(response => {
            //    this.coloradoJacksite = response.data;
            //}).catch((error) => {
            //});
        },
        mounted() {
            var mainContentDiv = document.querySelector(".content");
            var navbar = document.querySelector("#navbar-collapse");
            mainContentDiv.addEventListener('click', function (event) {
                navbar.classList.remove('show');
                if (this.isExpanded) {
                    this.isExpanded = false;
                }
                else {
                    this.isExpanded = true;
                }
            });
            //this.$('#navbar-collapse').on('scroll', function (event) {
            //    navbar.classList.remove('show');
            //    this.isExpanded = false;
            //});
        }

    }
</script>

<style>
    /* header {
        background-color: #fff;
        z-index: 999;
    }*/

    header {
        background-color: #fff;
        position: fixed; /* fixing the position takes it out of html flow - knows
                   nothing about where to locate itself except by browser
                   coordinates */
        left: 0; /* top left corner should start at leftmost spot */
        top: 0; /* top left corner should start at topmost spot */
        width: 100vw; /* take up the full browser width */
        z-index: 999; /* high z index so other content scrolls underneath */
        height: 50px; /* define height for content */
    }

    a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }

    html {
        font-size: 14px;
    }

    @media (min-width: 768px) {
        html {
            font-size: 16px;
        }
    }

    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }

    .count {
        top: -10px;
        left: -10px;
    }

    .count sup {
        width: 23px;
        height: 20px;
        line-height: 1.5rem;
        border-radius: 100%;
        border: 0;
        font-size: 14px;
        background-color: #E6A23C;
        text-align: center;
    }

    .profile-icon img {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        object-fit: cover;
    }

    .logo {
        width: 95%;
        height: auto;
        margin-top: -40px;
        margin-bottom: -50px
    }
   
    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 350px) {
       .logo {
            width: 60px;
            height: 60px;
        }
    }

     /*Small devices (landscape phones, 544px and up)*/ 
    @media (min-width: 544px) {
        .logo {
            width: 75px;
            height: 75px;
        }
    }

     
    @media (min-width: 768px) {
        .logo {
            width: 100px;
            height: 100px;
        }
    }

    /*Large devices (desktops, 992px and up)*/
    @media (min-width: 992px) {
        .logo {
            width: 125px;
            height: 125px;
        }
    }

     /*Extra large devices (large desktops, 1200px and up)*/ 
    @media (min-width: 1200px) {
        .logo {
            width: 150px;
            height: 140px;
        }
    }
</style>
