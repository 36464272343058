<template>
    <div class="shadow-card" style="padding: 30px; border-radius: 5px;" v-if="cart!=undefined&&cart.total > 0">
        <template class="d-none d-block">
            <div class="h5 pb-4">YOUR ORDER SUMMARY</div>
            <div class="row mb-2" v-for="product in cart.products" :key="product.id">
                <div class="col-3">
                    <div class="" style="max-width:100px">
                        <el-image :src="product.imageSrc">
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>
                </div>
                <div class="col-4">
                    {{product.name}}
                </div>
                <div class="col-2 text-right">
                    {{product.count}}
                </div>
                <div class="col-3 text-right">
                    ${{parseFloat(product.total).toFixed(2)}}
                </div>
            </div>
            <hr class="mt-4" v-if="shippingFee!=null" />

            <div class="row" v-if="shippingFee!=undefined">
                <div class="col-9">Subtotal</div>
                <div class="col-3">${{parseFloat(cart.total).toFixed(2)}} </div>
            </div>

            <div class="row" v-if="shippingFee!=null">
                <div class="col-9">Shipping</div>
                <div class="col-3"><span>$</span>{{parseFloat(shippingFee).toFixed(2)}}</div>
            </div>
            <div class="row" v-if="shippingFee!=undefined">
                <div class="col-9">Tax</div>
                <div class="col-3">$0.00 </div>
            </div>

            <hr class="mt-4" />
            <div class="row" v-if="shippingFee!=undefined">
                <div class="col-9 h5">Total</div>
                <div class="col-3">${{(parseFloat(cart.total) + parseFloat(shippingFee)).toFixed(2)}} </div>
            </div>
            <div class="row" v-else>
                <div class="col-9 h5">Subtotal</div>
                <div class="col-3 text-right">${{parseFloat(cart.total).toFixed(2)}}</div>
                <div class="col-12" style="font-size:12px">Does not include shipping </div>
            </div>
        </template>       
    </div>
</template>

<script>

    import store from "@/store"

    export default {
        name: "OrderReview",
        components: {},
        props: {
            shippingFee: Number
        },
        data() {
            return {
                cart: {}
            }
        },
        computed: {
           
        },
        methods: {
           
        },
        mounted() {
            this.cart = store.getters.cart;
            if (this.cart != undefined) {
                this.cart.products.forEach(p => {
                    p.showFlavors = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>